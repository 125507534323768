import React, { useMemo } from "react"
import { useApp } from "../../hooks/useApp"
import { useExistsInSavedItems, useToggleSavedItemsProduct } from "../../hooks/useSavedItems"

export const withPodcastCard = Component =>
  React.memo(({ name = "PodcastCard", ...props }) => {
    const { addToSavedItems, deleteFromSavedItems } = useToggleSavedItemsProduct()

    const { existsInSavedItems } = useExistsInSavedItems()
    const isExistsInSavedItems = existsInSavedItems(props?.id)

    const handleSavedItem = e => {
      e.preventDefault()
      if (isExistsInSavedItems) {
        deleteFromSavedItems(props?.id)
      } else {
        const image = props?.image.images.fallback.src
        addToSavedItems({ ...props, type: "podcast", image: { src: image } })
      }
    }

    const { customer } = useApp()
    const isLoggedIn = customer != null

    Component.displayName = name
    return useMemo(
      () => <Component {...props} handleSavedItem={handleSavedItem} existsInSavedItems={isExistsInSavedItems} isLoggedIn={isLoggedIn} />,
      [isExistsInSavedItems, customer]
    )
  })
