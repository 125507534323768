import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { H4, H5, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"

export const Card = tw(StyledCard)`w-full p-5 mb-0`
export const Frame = tw.figcaption``
export const Image = tw(GatsbyImage)`inline-block w-full rounded-lg mb-3 `
export const Title = tw(H4)`text-grey-dark mb-2 lg:text-md text-md`
export const Subtitle = tw(H5)`block font-normal lg:text-xxs text-xxs text-grey-mid leading-loose uppercase mb-0`
export const Content = tw(P)`text-grey-mid mb-5 lg:text-xs text-xs`
export const CardLink = tw(Link)`block`
export const MoreLink = tw(Link)`font-bold lg:text-xs text-xs text-grey-mid mb-0 leading-none uppercase`
export const DateTime = tw.time`inline-block font-bold lg:text-xs text-xs text-pink mt-2 border-t border-pink border-solid pt-1`
export const WishlistButton = styled.button`
  ${tw`absolute top-8 right-8 focus:outline-none hover:text-pink`}
  ${({active}) => active ? tw`text-pink` : tw`text-white`}
`