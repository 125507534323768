import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { H4 } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"

export const Card = tw(StyledCard)`p-0 w-70 bg-transparent rounded-md shadow-none hover:shadow-none max-w-full`
export const Frame = tw.figcaption``
export const Image = tw(GatsbyImage)`inline-block w-full pb-aspect-square h-0 mb-3 rounded-md group-hover:shadow-lg`
export const Title = tw(H4)`text-grey-dark mb-2 lg:text-md text-md`
export const CardLink = styled(Link)`
  ${({ size }) => (size === "full" ? tw`block` : tw`inline-block`)}
`
