import React, { useState } from "react"

import { withMentorCard } from "./withMentorCard"
import { Options } from "../Options/Options"
import {
  Card,
  CardLink,
  StyledLink,
  Frame,
  Recommended,
  Image,
  Title,
  Content,
  Subtitle,
  Badge,
  ViewLink,
  Button,
  ButtonContainer,
  WishlistButton,
  ToggleContent,
  ToggleRow,
  SkillsTitle,
  SkillsRow,
  Row,
  Column,
  Responsive,
} from "./MentorCard.styled"
import { Icon } from "../Icon/Icon"

export const MentorCard = withMentorCard(
  ({
    link,
    horizontal,
    toggle,
    image,
    recommended,
    wishlist,
    title,
    content,
    size,
    badge,
    badgeIcon,
    subtitle,
    feedback,
    toggleActive,
    handleActive,
    page: { profileLink, wishlistAdd, topHit },
    template: { feedbackTitle, skills },
    data: { profilePlaceholder },
    handleSavedItem,
    existsInSavedItems,
  }: Props): JSX.Element => {
    return (
      <CardLink size={size} horizontal={horizontal} recommended={recommended} to={link ? link : null}>
        <Responsive screen={`<md`}>
          <Card hover={toggle} onClick={handleActive} shadow={`medium`} size={size} horizontal={horizontal} recommended={recommended}>
            <Image
              image={{ ...(image || profilePlaceholder?.childImageSharp?.gatsbyImageData), width: 300, layout: "fullWidth" }}
              alt={title}
              size={size}
              horizontal={horizontal}
            />
            <Frame size={size} horizontal={horizontal} recommended={recommended}>
              {recommended || wishlist ? (
                <Row>
                  <Column position={`left`} width={`md:w-2/3`}>
                    {recommended ? (
                      <Recommended size={size} recommended={recommended}>
                        {topHit}
                      </Recommended>
                    ) : null}
                  </Column>
                </Row>
              ) : null}
              <Title size={size} horizontal={horizontal}>
                {title}
              </Title>
              <Content size={size} horizontal={horizontal}>
                {content}
              </Content>

              <Subtitle size={size} horizontal={horizontal}>
                {subtitle || ""}
              </Subtitle>
              {horizontal && badge ? (
                <Badge>
                  <Icon name={badgeIcon} size={`m`} colour={`green`} />
                  &nbsp;{badge}
                </Badge>
              ) : null}
            </Frame>

            {toggleActive ? (
              <ToggleContent>
                {skills.length ? (
                  <SkillsRow>
                    <SkillsTitle>{skills}</SkillsTitle>
                    <Options size={`pill-small`} items={skills} unclickable={true} />
                  </SkillsRow>
                ) : null}
                <ToggleRow>
                  {wishlist ? (
                    <Column position={`left`} width={`w-1/2`}>
                      <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </WishlistButton>
                    </Column>
                  ) : null}
                  <Column position={`right`} width={`w-1/2`}>
                    <StyledLink theme={`secondary`} to={link}>
                      {profileLink}
                    </StyledLink>
                  </Column>
                </ToggleRow>
              </ToggleContent>
            ) : null}

            {horizontal && feedback ? (
              <ButtonContainer>
                <Button theme={`minimal`} colour={`dark`} size={`medium`} onClick={feedback}>
                  {feedbackTitle}
                </Button>
              </ButtonContainer>
            ) : null}
          </Card>
        </Responsive>

        <Responsive screen={`md>`}>
          <Card hover={toggle} shadow={`medium`} size={size} horizontal={horizontal} recommended={recommended}>
            <Image
              image={{ ...(image || profilePlaceholder?.childImageSharp?.gatsbyImageData), width: 300, layout: "fullWidth" }}
              alt={title}
              size={size}
              horizontal={horizontal}
            />
            <Frame size={size} horizontal={horizontal} recommended={recommended}>
              {recommended || wishlist ? (
                <Row>
                  <Column position={`left`} width={`md:w-2/3`}>
                    {recommended ? (
                      <Recommended size={size} recommended={recommended}>
                        {topHit}
                      </Recommended>
                    ) : null}
                  </Column>

                  {wishlist ? (
                    <Column position={`right`} width={`md:w-1/3`}>
                      <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                        <Icon name={`heart`} size={`ml`} />
                      </WishlistButton>
                    </Column>
                  ) : null}
                </Row>
              ) : null}
              <Title size={size} horizontal={horizontal}>
                {title}
              </Title>
              <Content size={size} horizontal={horizontal}>
                {content}
              </Content>
              {!horizontal ? (
                <Button theme={`primary`} colour={`outline`} size={size === `full` || size === `less-medium` ? `medium` : size}>
                  {profileLink}
                </Button>
              ) : (
                <Subtitle size={size} horizontal={horizontal}>
                  {subtitle || ""}
                </Subtitle>
              )}
              {horizontal && badge ? (
                <Badge>
                  <Icon name={badgeIcon} size={`m`} colour={`green`} />
                  &nbsp;{badge}
                </Badge>
              ) : null}
              {horizontal ? (
                <Row>
                  <Column position={`right`} width={`w-full`}>
                    <ViewLink theme={`secondary`} to={link}>
                      {profileLink}
                    </ViewLink>
                  </Column>
                </Row>
              ) : null}
            </Frame>

            {horizontal && feedback ? (
              <ButtonContainer>
                <Button theme={`minimal`} colour={`dark`} size={`medium`} onClick={feedback}>
                  {feedbackTitle}
                </Button>
              </ButtonContainer>
            ) : null}
          </Card>
        </Responsive>
      </CardLink>
    )
  }
)

export interface Props {
  badge?: string
  badgeIcon?: string
  score?: any
  name?: string
  content?: string
  feedback?: () => void
  horizontal?: boolean
  image?: any
  link: string
  recommended?: boolean
  size?: string
  subtitle?: string
  title: string
  toggle?: boolean
  wishlist?: boolean
  noMargin?: boolean
  skills?: Array<any>
  toggleActive?: boolean
  handleActive?: () => void
  page?: any
  template?: any
  data?: any
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
}
