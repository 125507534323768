import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { Dashboard as Page } from "../../components/Customer/Dashboard/Dashboard"

export const query = graphql`
  query PageDashboardQuery($today: Date) {
    page: sanityPageAccountDashboard {
      title
      videoUrl
      content: _rawContent(resolveReferences: { maxDepth: 3 })
      additionalWelcomeNewMember
      additionalWelcomeReturningMember
    }
    events: allSanityEvent(
      filter: { date: { gte: $today }, attributes: { publishedAt: { ne: null } } }
      sort: { fields: date, order: ASC }
      limit: 10
    ) {
      edges {
        node {
          ...GatsbyEventFragment
        }
      }
    }
    podcasts: allSanityPodcast(
      limit: 6
      filter: { attributes: { publishedAt: { ne: null } } }
      sort: { fields: attributes___publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...GatsbyPodcastFragment
        }
      }
    }
    articles: allSanityArticle(
      limit: 4
      filter: { attributes: { publishedAt: { ne: null } } }
      sort: { fields: attributes___publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...GatsbyArticleFragment
        }
      }
    }
  }
`

export default withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
