import React, { useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useData } from "../../../hooks/useData"
import { useApp } from "../../../hooks/useApp"
import { useImage } from "../../../hooks/useImage"
import { useSanityMentor } from "../../../hooks/useSanity"

export const withDashboard = Component =>
  React.memo(({ name = "Dashboard", page, events, podcasts, articles }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { data } = useData()
    const { customer, member, ready } = useApp()
    const { recommendedMentors, getRecommendedMentors, newArrivalMentors, getNewArrivalMentors } = useSanityMentor()
    const { getGatsbyImageData } = useImage()

    const title = page?.title
    const content = sanityContent(page?.content)
    const videoUrl = page?.videoUrl
    const messages = {newMember: page.additionalWelcomeNewMember, returningMember: page.additionalWelcomeReturningMember}

    if (ready) getNewArrivalMentors(member?._id ? member?.contact?.email : customer?.email, 8)

    if (ready)
      getRecommendedMentors(
        member?._id
          ? {
              preferences: member?.preferences,
              contact: member?.contact,
              email: member?.email,
            }
          : {
              preferences: customer?.preferences,
              contact: customer?.contact,
              email: customer?.email,
            },
        8
      )

    const eventItems =
      events?.edges?.map(({ node }) => ({
        ...node,
        image: node?.image && getGatsbyImageData(node?.image, { aspectRatio: 1, width: 800 }),
      })) || []

    const podcastItems =
      podcasts?.edges?.map(({ node }) => ({
        ...node,
        image: node?.image && getGatsbyImageData(node?.image, { aspectRatio: 1, width: 800 }),
      })) || []

    const articleItems =
      articles?.edges?.map(({ node }) => ({
        ...node,
        image: node?.image && getGatsbyImageData(node?.image, { aspectRatio: 1, width: 800 }),
      })) || []

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={title}
          content={content}
          data={data}
          customer={customer}
          member={member}
          mentors={recommendedMentors}
          newMentors={newArrivalMentors}
          events={eventItems}
          podcasts={podcastItems}
          articles={articleItems}
          ready={ready}
          videoUrl={videoUrl}
          messages={messages}
        />
      ),
      [customer, member, recommendedMentors, ready]
    )
  })
