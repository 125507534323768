import React from "react"

import { Card, Frame, Image, Title, CardLink } from "./CardNews.styled"

export const CardNews = ({ size, link, card = false, image, title, ...props }: Props): JSX.Element => (
  <CardLink className={`group`} size={size} to={link}>
    <Card card={card} size={"large"} shadow={"medium"} hover>
      {image ? <Image alt={title} image={image} {...props} /> : null}
      <Frame {...props}>
        <Title {...props}>{title}</Title>
      </Frame>
    </Card>
  </CardLink>
)

export interface Props {
  title: string
  link: string
  card?: any
  date?: any
  image?: any
  size?: string
}
