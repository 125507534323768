import React from "react"

import { withPodcastCard } from "./withPodcastCard"
import { Card, Frame, Image, Title, DateTime, Subtitle, Content, CardLink, MoreLink, WishlistButton } from "./PodcastCard.styled"
import { StyledRow } from "../../styled/Row"
import { StyledColumn } from "../../styled/Column"
import { StyledResponsive } from "../../styled/Responsive"
import { Icon } from "../Icon/Icon"
import { getFluidGatsbyImage } from "../Wrappers/Helpers"

export const PodcastCard = withPodcastCard(
  ({ link, image, title, content, subtitle, date, handleSavedItem, existsInSavedItems, isLoggedIn, ...props }: Props): JSX.Element => (
    <CardLink to={link}>
      <Card size={"large"} shadow={"medium"} hover>        
        {image && <Image alt={title} image={image} />}
        <Frame {...props}>
          <Title {...props}>{title}</Title>
          {content ? <Content>{content}</Content> : null}
          {!image ? <MoreLink to={link}>Listen Now</MoreLink> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          <StyledRow>
            <StyledColumn position={"left"} width={"md:w-2/3"}>
                {date && image ? (
                <DateTime>
                  {new Intl.DateTimeFormat("en-AU", {
                    year: "numeric",
                    timeZone: "Australia/Melbourne",
                  }).format(Date.parse(date))}
                </DateTime>
              ) : null}
            </StyledColumn>
            {
              isLoggedIn &&  
              <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
                <Icon name={`heart`} size={`ml`} />
              </WishlistButton>
            }
          </StyledRow>
        </Frame>
      </Card>
    </CardLink>
  )
)

export interface Props {
  title: string
  link: string
  subtitle?: string
  content?: string
  image?: any
  date?: any
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
  isLoggedIn?: boolean
}
