import { useStaticQuery, graphql } from "gatsby"

export const usePage = () => {
  const { mentors } = useStaticQuery(graphql`
    query usePage {
      mentors: sanityPageMentors {
        allResults: additionalAllResults
        filter: additionalFilter
        filterSkills: additionalFilterSkills
        filterIndustry: additionalFilterIndustry
        filterLevel: additionalFilterLevel
        filterLocation: additionalFilterLocation
        filterLanguages: additionalFilterLanguages
        filterAvailability: additionalFilterAvailability
        profileLink: additionalProfileLink
        resultsCount: additionalResults
        resultsCountPlural: additionalResultsPlural
        searchPlaceholder: additionalSearchPlaceholder
        sort: additionalSort
        sortOptions: settingSortOptions {
          title
          field
          id: _key
        }
        topHit: additionalTopHit
        wishlistAdd: additionalWishlistAdd
        wishlistRemove: additionalWishlistRemove
      }
    }
  `)

  return { mentors }
}
