import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import { withDashboard } from "./withDashboard"

import { EventCard } from "../../Event/EventCard"
import { MentorCard } from "../../Mentor/MentorCard"
import { PodcastCard } from "../../Podcast/PodcastCard"
import { CardNews } from "../../Cards/CardNews"
import { Grid } from "../../Grid/Grid"
import { Hero } from "../../Section/Hero/Hero"
import { CallToAction } from "../../CallToAction/CallToAction"
import { VideoDetails } from "../../Video/VideoDetails"

import { P } from "../../../styled/Text"
import { StyledContainer } from "../../../styled/Container"
import { StyledRow } from "../../../styled/Row"
import { StyledColumn } from "../../../styled/Column"
import { StyledGridColumn } from "../../../styled/Grid"
import { StyledHeroP, StyledHeroH1 } from "../../../styled/Hero"
import { StyledHr } from "../../../styled/Rule"
import { StyledResponsive } from "../../../styled/Responsive"
import { Image } from "../../../components/Acccount/Widget/Widget.styled"

const Row = styled(StyledRow)`
  ${tw`text-left`}
`
const Caption = styled(P)`
  ${tw`text-center mt-6`}
`
const ProfileButton = tw.button`text-center mr-4 rounded-full items-center justify-center h-18 w-18 bg-blend hover:bg-overlay`

const ProfileText = styled(P)`
  ${tw`text-white text-xxs`}
`

export const Dashboard = withDashboard(
  ({ title, data, customer, member, mentors, newMentors, events, podcasts, articles, ready, videoUrl, messages }): JSX.Element => {
    return (
      <>
        <Hero colour={"secondary"} size={member ? "xlarge" : "medium"} extended={false}>
          <StyledContainer width={`lg`}>
            
            <StyledResponsive screen="all">
              <Row center>
                <StyledColumn width={`w-1/4`} className="flex justify-end">
                  {ready && (
                    <ProfileButton
                      as={Link}
                      to={customer ? "/account/profile" : "/account/login"}
                      title={member?.title ? member?.title : customer ? customer?.firstName : null}
                      className={!member || !member?.imageUrl ? "flex group" : "block group"}
                    >
                      {!member ? (
                        <ProfileText>Complete Profile</ProfileText>
                      ) : (
                        member?.imageUrl && ready ? <Image image={{ ...member?.image, layout: "fullWidth" }} alt={member?.title} />
                        : <ProfileText>Edit Profile</ProfileText>
                      )}
                    </ProfileButton>
                  )}
                </StyledColumn>
                <StyledColumn width={`w-3/4`} className="pt-3 md:pt-0">
                  <StyledHeroH1>Hi {customer?.firstName}</StyledHeroH1>
                  <StyledHeroP>{!member ? messages?.newMember || title : messages?.returningMember || title}</StyledHeroP>
                </StyledColumn>
              </Row>
            </StyledResponsive>
          </StyledContainer>
        </Hero>

        {/* {!member && ready && (
               <> */}
        <StyledContainer spacing={`lg`} width={`sm`}>
          <VideoDetails
            url={videoUrl}
            // image={data.videoImage.childImageSharp.fluid}
            autoplay={false}
            loop={true}
            muted={false}
            playPause={true}
            controls={false}
            ratio={"16/9"}
            hidden={true}
          />
          <Caption>Watch a welcome video with our founder Ali.</Caption>
        </StyledContainer>

        <StyledResponsive>
          <StyledContainer spacing={`md`} width={`xl`}>
            <StyledHr />
          </StyledContainer>
        </StyledResponsive>
        {/* </>
             )} */}

        {customer && mentors?.length > 0 && ready && (
          <StyledContainer collapse={`<md`} spacing={`md`} width={`xl`}>
            <Grid
              show={4}
              theme={member ? `` : ``} //md:white
              title={member ? "Top picks for you" : "Recommended for you"}
              viewMoreButton={`View all mentors`}
              viewMoreButtonMobile={member ? "Browse all mentors" : "Join the mentor hour!"}
              viewMoreLink={`/mentors`}
            >
              {mentors?.map(item => (
                <StyledGridColumn>
                  <MentorCard
                    noMargin
                    size={"full"}
                    image={item?.image}
                    title={item?.title}
                    content={`${item?.role || "Mentor"}${item?.company ? `, ${item?.company}` : ""}`}
                    link={`/mentors/${item?.slug?.current}`}
                  />
                </StyledGridColumn>
              ))}
            </Grid>
          </StyledContainer>
        )}

        {customer && newMentors?.length > 0 && ready && (
          <StyledContainer collapse={`<md`} spacing={`md`} width={`xl`}>
            <Grid
              show={4}
              theme={member ? `` : ``} //md:white
              title={`New Arrivals`}
              viewMoreButton={`View all mentors`}
              viewMoreButtonMobile={member ? "Browse all mentors" : "Join the mentor hour!"}
              viewMoreLink={`/mentors`}
            >
              {newMentors?.map(item => (
                <StyledGridColumn>
                  <MentorCard
                    noMargin
                    size={"full"}
                    image={item?.image}
                    title={item?.title}
                    content={`${item?.role || "Mentor"}${item?.company ? `, ${item?.company}` : ""}`}
                    link={`/mentors/${item?.slug?.current}`}
                  />
                </StyledGridColumn>
              ))}
            </Grid>
          </StyledContainer>
        )}

        {customer && events?.length > 0 && (
          <>
            <StyledContainer spacing={`md`} width={`xl`}>
              <StyledHr />
            </StyledContainer>
            <StyledContainer spacing={`md`} width={`xl`}>
              <Grid
                show={3}
                stack
                title={`Upcoming events`}
                viewMoreButton={`View all events`}
                viewMoreButtonMobile={`View all events`}
                viewMoreLink={`/events`}
              >
                {events
                  ?.filter(item => {
                    const today = new Date()
                    const date = item.date && new Date(item.date)
                    return date && date.getTime() >= today.getTime()
                  })
                  ?.map((item, index) => (
                    <StyledGridColumn stack key={index}>                      
                      <StyledResponsive screen={`md>`}>
                        <EventCard
                          id={item?.id}
                          size={"full"}
                          image={item?.image}
                          title={item?.title}
                          content={item?.summary}
                          link={`/events/${item?.slug?.current}`}
                          location={item?.location}
                          date={new Date(item?.date)}
                          status={"Attending"}
                          members={item?.attendees?.map(attendee => attendee?.image)}
                        />
                      </StyledResponsive>
                      <StyledResponsive screen={`<md`}>
                        <EventCard
                          id={item?.id}
                          size={"full"}
                          image={item?.image}
                          title={item?.title}
                          content={item?.summary}
                          link={`/events/${item?.slug?.current}`}
                          date={new Date(item?.date)}
                          status={"Attending"}
                          horizontal
                        />
                      </StyledResponsive>
                    </StyledGridColumn>
                  ))}
              </Grid>
            </StyledContainer>
          </>
        )}

        {customer && podcasts?.length > 0 && (
          <>
            <StyledContainer spacing={`md`} width={`xl`}>
              <StyledHr />
            </StyledContainer>
            <StyledContainer collapse={`<md`} spacing={`lg`} width={`xl`}>
              <Grid show={3} title={`Latest podcasts`} viewMoreButton={`View all podcasts`} viewMoreLink={`/podcasts`}>
                {podcasts?.map(podcast => (
                  <StyledGridColumn>
                    <PodcastCard
                      id={podcast?.id}
                      image={podcast?.image}
                      title={podcast?.title}
                      subtitle={podcast?.summary}
                      link={`/podcasts/${podcast?.slug?.current}`}
                      date={podcast?.attributes?.publishedAt && new Date(podcast?.attributes?.publishedAt)}
                    />
                  </StyledGridColumn>
                ))}
              </Grid>
            </StyledContainer>
          </>
        )}

        {customer && articles?.length > 0 && (
          <>
            <StyledContainer spacing={`md`} width={`xl`}>
              <StyledHr />
            </StyledContainer>
            <StyledContainer collapse={`<md`} spacing={`lg`} width={`xl`}>
              <Grid show={4} title={`Latest news`} viewMoreButton={`View all news`} viewMoreLink={`/articles`}>
                {articles?.map(article => (
                  <StyledGridColumn>
                    <CardNews size={`full`} image={article?.image} title={article?.title} link={`/articles/${article?.slug?.current}`} />
                  </StyledGridColumn>
                ))}
              </Grid>
            </StyledContainer>
          </>
        )}
        {/* <CallToAction
        title={"Don’t miss out!"}
        mainContent={"Jessica is chairing a Mentor Hotseat on Friday the 24th November at 8:00pm"}
        smallContent={"What’s the Mentor Hotseat?"}
        smallContentLinkText={"Find out"}
        smallContentLink={"/about/the-mentor-hotseat"}
        bgColour={"primary"}
        buttonText={"Submit a question"}
        buttonLink={"/hotseats/particular-hotseat/ask"}
        buttonTheme={"primary"}
        buttonColour={"light"}
        buttonSize={"large"}
        buttonTail
      /> */}
      </>
    )
  }
)
