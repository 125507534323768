import React, { useState, useMemo } from "react"

export const withVideoDetails = Component =>
  React.memo(({ name = "VideoDetails", autoplay, ...props }) => {
    const [playing, setPlaying] = useState(autoplay)
    const [played, setPlayed] = useState(autoplay)

    const handleToggle = () => {
      setPlaying(!playing)
      !played ? setPlayed(true) : false
    }

    const handleEnded = () => {
      setPlayed(false)
      setPlaying(false)
    }

    Component.displayName = name
    return useMemo(
      () => <Component {...props} playing={playing} played={played} autoplay={autoplay} handleToggle={handleToggle} handleEnded={handleEnded} />,
      [playing, played]
    )
  })
