import React from "react"

import { withVideoDetails } from "./withVideoDetails"
import { StyledVideoSection, StyledVideoPlayer, StyledVideoControl, StyledVideoOverlay, StyledVideoImage } from "./VideoDetails.styled"
import { Icon } from "../Icon/Icon"

export const VideoDetails = withVideoDetails(
  ({
    ratio,
    playPause = true,
    poster,
    url,
    loop = false,
    muted = false,
    controls = false,
    playOnClick = true,
    playing,
    played,
    hidden = false,
    handleToggle,
    handleEnded,
    onClick = () => false,
  }: Props): JSX.Element => (
    <StyledVideoSection ratio={ratio} onClick={onClick}>
      {playPause && (
        <StyledVideoControl onClick={() => playOnClick && handleToggle()}>
          {!played && poster && <StyledVideoImage image={poster} />}
          <StyledVideoOverlay active={!playing && played} />
          {!playing && <Icon name={"play"} size={"s"} colour={"secondary"} disc />}
        </StyledVideoControl>
      )}
      {!poster && hidden && (
        <StyledVideoPlayer
          url={url}
          playing={playing}
          loop={loop}
          muted={muted}
          controls={controls}
          playsinline
          volume={1}
          width={"100%"}
          height={"100%"}
          onEnded={handleEnded}
          config={{ youtube: { playerVars: { disablekb: 1 } } }}
        />
      )}
    </StyledVideoSection>
  )
)

export interface Props {
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  muted?: boolean
  hidden?: boolean
  playPause?: boolean
  playing?: boolean
  poster?: string
  ratio?: "square" | "16/9" | "4/3" | "21/9"
  url?: string
  playOnClick?: boolean
  played?: boolean
  onClick?: () => void
  handleToggle?: () => void
  handleEnded?: () => void
}
