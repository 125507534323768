import React, { useMemo, useState } from "react"

import { usePage } from "../../hooks/usePage"
import { useTemplate } from "../../hooks/useTemplate"
import { useData } from "../../hooks/useData"
import { useToggleSavedItemsProduct, useExistsInSavedItems } from "../../hooks/useSavedItems"
import { Props } from "./MentorCard"
export const withMentorCard = Component =>
  React.memo(({ name = "MentorCard", score, recommended, ...props }: Props) => {
    const [toggleActive, setActive] = useState(false)
    const { mentors: page } = usePage()
    const { mentor: template } = useTemplate()

    const { data } = useData()

    const { addToSavedItems, deleteFromSavedItems } = useToggleSavedItemsProduct()

    const { existsInSavedItems } = useExistsInSavedItems()
    const isExistsInSavedItems = existsInSavedItems(props?.id)

    let heartIconClicked = false
    const handleSavedItem = e => {
      e.preventDefault()
      if (isExistsInSavedItems) {
        deleteFromSavedItems(props?.id)
      } else {
        addToSavedItems({ ...props, type: "mentor", image: { src: props.image } })
      }
      heartIconClicked = true
    }

    const handleActive = e => {
      e.preventDefault()
      if (!heartIconClicked) {
        setActive(!toggleActive)
      }
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          template={template}
          page={page}
          data={data}
          recommended={recommended}
          toggleActive={toggleActive}
          handleActive={handleActive}
          handleSavedItem={handleSavedItem}
          existsInSavedItems={isExistsInSavedItems}
        />
      ),
      [score, recommended, isExistsInSavedItems, toggleActive]
    )
  })
