import tw, { styled } from "twin.macro"

const styles = {
  rule: tw`border-t border-grey-pale w-full`,
  spacing: {
    md: tw`my-4`,
    lg: tw`my-8`,
  },
}

export const StyledHr = styled.hr<{
  spacing: string
}>`
  ${styles.rule}
  ${({ spacing }) => styles.spacing[spacing] || null}
`
